import React from 'react';
import { bool, func, node, object, shape, string, array, number } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Field, { hasDataInFields } from '../../Field';
import {
  ListingCard, NamedLink,
} from '../../../../components';

import SectionContainer from '../SectionContainer';
import css from './SectionListings.module.css';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionListingsComponent = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
    listings = [],
    numCols = 3,
    viewAllCategory,
    isSquare,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const isForFeatured =  Array.isArray(listings) && listings.length <= 2;

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const classes = isForFeatured ? classNames(rootClassName || css.root, className, css.blankBG)
    : classNames(rootClassName || css.root, className);

  const listings_content = () => {
    const cardRenderSizes = isMapVariant => {
      if (isMapVariant) {
        // Panel width relative to the viewport
        const panelMediumWidth = 50;
        const panelLargeWidth = 62.5;
        return [
          '(max-width: 767px) 100vw',
          `(max-width: 1023px) ${panelMediumWidth}vw`,
          `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
          `${panelLargeWidth / 3}vw`,
        ].join(', ');
      } else {
        // Panel width relative to the viewport
        const panelMediumWidth = 50;
        const panelLargeWidth = 62.5;
        return [
          '(max-width: 549px) 100vw',
          '(max-width: 767px) 50vw',
          `(max-width: 1439px) 26vw`,
          `(max-width: 1920px) 18vw`,
          `14vw`,
        ].join(', ');
      }
    };
    return (
      <div className={css.rootListingCards}>
        <div className={css[`listingCards-${numCols}`]}>
          {listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes(false)}
              isSquare={isSquare}
            />
          ))}
          {props.children}
        </div>
      </div>
    );
  }

  const view_all_content = () => {
    if (!viewAllCategory) {
      return null;
    }
    const experience = {
      'pollinator-garden': 'pollinator-garden',
      'small-space': 'small-space-gardening',
      'unique': 'rare-unique-varieties',
    };
    if (Object.keys(experience).includes(viewAllCategory)) {
      return (
        <div className={css.viewAll}>
          <NamedLink name="ExperiencePage" params={{ pub_experience: experience[viewAllCategory] }}>
            VIEW ALL
          </NamedLink>
        </div>
      )
    }
    if (viewAllCategory === 'on-sale') {
      return (
        <div className={css.viewAll}>
          <NamedLink name="SearchPage" to={{ search: '?pub_on_sale=true' }}>
            VIEW ALL
          </NamedLink>
        </div>
      )
    }
    return (
      <div className={css.viewAll}>
        <NamedLink name="SpecialtyPage" params={{ pub_specialty: viewAllCategory }}>
          VIEW ALL
        </NamedLink>
      </div>
    )
  }

  return (
    <SectionContainer
      id={sectionId}
      className={classes}
      rootClassName={classNames(rootClassName || css.root)}
      contentClassName={css.sectionContent}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.contentWrapper}>
        {hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
            <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          </header>
        ) : null}
        <div
          className={classNames(
            css.blockContainer,
            defaultClasses.blockContainer,
            // getColumnCSS(numColumns),
            {
              [css.noSidePaddings]: true,
            },
          )}
        >
          {view_all_content()}
          {listings_content()}
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionListingsComponent.defaultProps = {
  className: null,
  listings: [],
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
  numCols: 3,
  viewAllCategory: null,
  isSquare: true,
};

SectionListingsComponent.propTypes = {
  sectionId: string.isRequired,
  listings: array,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
  viewAllCategory: string,
  isSquare: bool,

  location: shape({
    search: string.isRequired,
  }).isRequired,

  numCols: number,
};

const SectionListings = compose(
  withRouter,
)(SectionListingsComponent);

export default SectionListings;

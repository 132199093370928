import React from 'react';
import { bool, func, node, object, shape, string, array } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Field, { hasDataInFields } from '../../Field';
import {
  ListingCard,
  NamedLink,
} from '../../../../components';

import SectionContainer from '../SectionContainer';
import css from './SectionLatestListings.module.css';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionLatestListingsComponent = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
    listings = [],
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const classes = classNames(rootClassName || css.root, className);

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  const primaryListing = listings[0];
  const secondaryListings = listings.slice(1);

  return (
    <SectionContainer
      id={sectionId}
      className={classes}
      rootClassName={classNames(rootClassName || css.root)}
      contentClassName={css.sectionContent}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.contentWrapper}>
        {hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
            <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          </header>
        ) : null}
        <div
          className={classNames(
            css.blockContainer,
            defaultClasses.blockContainer,
            // getColumnCSS(numColumns),
            {
              [css.noSidePaddings]: true,
            },
          )}
        >
          <div className={css.viewAll}>
            <NamedLink name="SearchPage" params={{ sort: 'createdAt' }}>
              VIEW ALL
            </NamedLink>
          </div>
          <div className={css.rootListingCards}>
            <div className={css.listingCards}>
              <div className={css.primaryCard}>
                {
                  primaryListing
                  && (
                    <ListingCard
                      className={css.listingCard}
                      key={primaryListing?.id?.uuid}
                      listing={primaryListing}
                      renderSizes={cardRenderSizes(false)}
                    />
                  )
                }
              </div>
              <div className={css.secondaryCards}>
                {secondaryListings.map(l => (
                  <div className={css.secondaryCardWrapper} key={l.id.uuid}>
                    <ListingCard
                      className={css.listingCard}
                      key={l?.id?.uuid}
                      listing={l}
                      renderSizes={cardRenderSizes(false)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionLatestListingsComponent.defaultProps = {
  className: null,
  listings: [],
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionLatestListingsComponent.propTypes = {
  sectionId: string.isRequired,
  listings: array,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,

  location: shape({
    search: string.isRequired,
  }).isRequired,
};

const SectionLatestListings = compose(
  withRouter,
)(SectionLatestListingsComponent);

export default SectionLatestListings;

import React, { useEffect } from 'react';
import { bool, func, node, object, shape, string, array, number } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Field, { hasDataInFields } from '../../Field';
import {
  ListingCard,
  NamedLink,

} from '../../../../components';

import SectionContainer from '../SectionContainer';
import css from './SectionCuratedListings.module.css';

const KEY_CODE_ARROW_LEFT = 37;
const KEY_CODE_ARROW_RIGHT = 39;

// The number of columns (numColumns) affects styling and responsive images
const COLUMN_CONFIG = [
  { css: css.oneColumn, responsiveImageSizes: '(max-width: 767px) 100vw, 1200px' },
  { css: css.twoColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 600px' },
  { css: css.threeColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 400px' },
  { css: css.fourColumns, responsiveImageSizes: '(max-width: 767px) 100vw, 290px' },
];
const getIndex = numColumns => numColumns - 1;
const getColumnCSS = numColumns => {
  const config = COLUMN_CONFIG[getIndex(numColumns)];
  return config ? config.css : COLUMN_CONFIG[0].css;
};

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionCuratedListingsComponent = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
    carouselListings = [],
    stackListings = [],
    numCols = 4,
  } = props;

  const sliderContainerId = `${props.sectionId}-carousel-container`;
  const sliderBtnContainerId = `${props.sectionId}-carousel-btn-container`;
  const sliderId = `${props.sectionId}-carousel-slider`;
  const numberOfBlocks = carouselListings?.length;
  const hasBlocks = numberOfBlocks > 0;
  const gradientClass = css.gradient;
  const numSliderCols = 1;

  useEffect(() => {
    const setCarouselWidth = () => {
      if (hasBlocks) {
        const windowWidth = window.innerWidth;
        const elem = window.document.getElementById(sliderContainerId);
        const scrollbarWidth = window.innerWidth - document.body.clientWidth;
        const elementWidth =
          elem.clientWidth >= windowWidth - scrollbarWidth ? windowWidth : elem.clientWidth;
        const carouselWidth = elementWidth - scrollbarWidth;

        elem.style.setProperty('--carouselWidth', `${carouselWidth}px`);
      }
    };
    setCarouselWidth();

    window.addEventListener('resize', setCarouselWidth);
    return () => window.removeEventListener('resize', setCarouselWidth);
  }, []);

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const isForFeatured =  Array.isArray(carouselListings) && carouselListings.length <= 2;

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const classes = isForFeatured ? classNames(rootClassName || css.root, className, css.blankBG)
    : classNames(rootClassName || css.root, className);

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };
  const listings_content = () => {
    return (
      <div className={css.rootListingCards}>
        <div className={classNames(css[`listingCards-${numCols}`], css.carouselHiddenMobile)}>
          {carouselListings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes(false)}
            />
          ))}
          {props.children}
        </div>
        <div className={css[`listingCards-${numCols}`]}>
          {stackListings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes(false)}
            />
          ))}
          {props.children}
        </div>
      </div>
    );
  }

  const onSlideLeft = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numSliderCols * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft - slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onSlideRight = e => {
    var slider = window.document.getElementById(sliderId);
    const slideWidth = numSliderCols * slider?.firstChild?.clientWidth;
    slider.scrollLeft = slider.scrollLeft + slideWidth;
    // Fix for Safari
    e.target.focus();
  };

  const onKeyDown = e => {
    if (e.keyCode === KEY_CODE_ARROW_LEFT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideLeft(e);
    } else if (e.keyCode === KEY_CODE_ARROW_RIGHT) {
      // Prevent changing cursor position in input
      e.preventDefault();
      onSlideRight(e);
    }
  };

  const carousel_content = () => {
    return (
      <div className={css.carouselContainer} id={sliderContainerId}>
        <div
          className={classNames(css.carouselArrows, {
            [css.notEnoughBlocks]: numberOfBlocks <= numSliderCols,
          }, css[sliderBtnContainerId])}
        >
          <button className={css.carouselArrowPrev} onClick={onSlideLeft} onKeyDown={onKeyDown}>
            ‹
          </button>
          <button className={css.carouselArrowNext} onClick={onSlideRight} onKeyDown={onKeyDown}>
            ›
          </button>
        </div>
        <div className={getColumnCSS(numSliderCols)} id={sliderId}>
          {carouselListings.map(l => (
            <ListingCard
              className={classNames(css.block, css.listingCard)}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes(false)}
            />
          ))}
        </div>
        <div className={gradientClass} />
      </div>
    );
  }

  const view_all_content = () => {
    return null;
    if (isForFeatured) {
      return null;
    }
    return (
      <div className={css.viewAll}>
        <NamedLink name="SpecialtyPage" params={{ pub_specialty: 'seasonal-garden-essentials' }}>
          VIEW ALL
        </NamedLink>
      </div>
    )
  }

  return (
    <SectionContainer
      id={sectionId}
      className={classes}
      rootClassName={classNames(rootClassName || css.root)}
      contentClassName={css.sectionContent}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.contentWrapper}>
        {hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
            <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          </header>
        ) : null}
        <div
          className={classNames(
            css.blockContainer,
            defaultClasses.blockContainer,
            // getColumnCSS(numColumns),
            {
              [css.noSidePaddings]: true,
            },
          )}
        >
          {view_all_content()}
          {carousel_content()}
          {listings_content()}
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionCuratedListingsComponent.defaultProps = {
  className: null,
  carousel_listings: [],
  stack_listings: [],
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
  numCols: 4,
};

SectionCuratedListingsComponent.propTypes = {
  sectionId: string.isRequired,
  carouselListings: array,
  stackListings: array,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,

  location: shape({
    search: string.isRequired,
  }).isRequired,

  numCols: number,
};

const SectionListings = compose(
  withRouter,
)(SectionCuratedListingsComponent);

export default SectionListings;

import React, { useState } from 'react';

import { IconSpinner, LayoutComposer } from '../../components/index.js';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer.js';
import FooterContainer from '../FooterContainer/FooterContainer.js';

import { validProps } from './Field';

import SectionBuilder from './SectionBuilder/SectionBuilder.js';
import StaticPage from './StaticPage.js';

import css from './PageBuilder.module.css';
import { set } from 'lodash';

const getMetadata = (meta, schemaType, fieldOptions) => {
  const { pageTitle, pageDescription, socialSharing } = meta;

  // pageTitle is used for <title> tag in addition to page schema for SEO
  const title = validProps(pageTitle, fieldOptions)?.content;
  // pageDescription is used for different <meta> tags in addition to page schema for SEO
  const description = validProps(pageDescription, fieldOptions)?.content;
  // Data used when the page is shared in social media services
  const openGraph = validProps(socialSharing, fieldOptions);
  // We add OpenGraph image as schema image if it exists.
  const schemaImage = openGraph?.images1200?.[0]?.url;
  const schemaImageMaybe = schemaImage ? { image: [schemaImage] } : {};
  const isArticle = ['Article', 'NewsArticle', 'TechArticle'].includes(schemaType);
  const schemaHeadlineMaybe = isArticle ? { headline: title } : {};

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org (This template uses JSON-LD format)
  //
  // In addition to this schema data for search engines, src/components/Page/Page.js adds some extra schemas
  // Read more about schema:
  // - https://schema.org/
  // - https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data
  const pageSchemaForSEO = {
    '@context': 'http://schema.org',
    '@type': schemaType || 'WebPage',
    description: description,
    name: title,
    ...schemaHeadlineMaybe,
    ...schemaImageMaybe,
  };

  return {
    title,
    description,
    schema: pageSchemaForSEO,
    socialSharing: openGraph,
  };
};

const LoadingSpinner = () => {
  return (
    <div className={css.loading}>
      <IconSpinner delay={600} />
    </div>
  );
};

//////////////////
// Page Builder //
//////////////////

/**
 * PageBuilder can be used to build content pages using page-asset.json.
 *
 * Note: props can include a lot of things that depend on
 * - pageAssetsData: json asset that contains instructions how to build the page content
 *   - asset should contain an array of _sections_, which might contain _fields_ and an array of _blocks_
 *     - _blocks_ can also contain _fields_
 * - fallbackPage: component. If asset loading fails, this is used instead.
 * - options: extra mapping of 3 level of sub components
 *   - sectionComponents: { ['my-section-type']: { component: MySection } }
 *   - blockComponents: { ['my-component-type']: { component: MyBlock } }
 *   - fieldComponents: { ['my-field-type']: { component: MyField, pickValidProps: data => Number.isInteger(data.content) ? { content: data.content } : {} }
 *     - fields have this pickValidProps as an extra requirement for data validation.
 * - pageProps: props that are passed to src/components/Page/Page.js component
 *
 * @param {Object} props
 * @returns page component
 */
const PageBuilder = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    fallbackPage,
    schemaType,
    options,
    onSearchSubmit,
    featured_listings,
    latest_listings,
    promoted_listings,
    curated_carousel_listings,
    curated_stack_listings,
    pollinator_listings,
    small_space_listings,
    unique_listings,
    on_sale_listings,
    params,
    ...pageProps
  } = props;

  // console.log('params', params);
  const {
    sectionId = 'orders',
  } = params || {};

  const [sideNavTab, setSideNavTab] = useState(`faq-${sectionId}`);

  if (!pageAssetsData && fallbackPage && !inProgress && error) {
    return fallbackPage;
  }

  // Page asset contains UI info and metadata related to it.
  // - "sections" (data that goes inside <body>)
  // - "meta" (which is data that goes inside <head>)
  const { sections = [], meta = {} } = pageAssetsData || {};
  const pageMetaProps = getMetadata(meta, schemaType, options?.fieldComponents);
  const {
    title,
  } = pageMetaProps || {};
  let hasSideNav = false;
  if (`${title}`.indexOf('Frequently Asked Questions') >= 0) {
    hasSideNav = true;
  }

  let side_nav_items = [];
  let sidenav_content = null;
  if (hasSideNav) {
    [...sections].slice(1).forEach((section) => {
      side_nav_items.push({
        id: section.sectionId,
        name: section.sectionName,
      });
    });
    // console.log('side_nav_items', side_nav_items);
    sidenav_content = (
      <div className={css.sideNavItems}>
        {
          side_nav_items.map((item) => {
            const linkClassName = item.id === sideNavTab ? css.active : '';
            return (
              <div className={css.sideNavItem}>
                <a onClick={() => setSideNavTab(item.id)} className={linkClassName}>{item.name}</a>
              </div>
            )
          })
        }
      </div>
    );
  }

  const layoutAreas = `
    topbar
    main
    footer
  `;
  return (
    <StaticPage {...pageMetaProps} {...pageProps}>
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          const pageContent = hasSideNav ? (
            <>
              <SectionBuilder
                sections={[sections[0]]}
                options={options}
              />
              <div className={css.sideNavLayout}>
                <div className={css.sideNav}>
                  {sidenav_content}
                </div>
                <div className={css.mainContent}>
                  <SectionBuilder
                    sections={sections.slice(1)}
                    options={options}
                    sideNavTab={sideNavTab}
                  />
                </div>
              </div>
            </>
          ) : (
            <SectionBuilder
              sections={sections}
              options={options}
              onSearchSubmit={onSearchSubmit}
              featured_listings={featured_listings}
              latest_listings={latest_listings}
              promoted_listings={promoted_listings}
              curated_carousel_listings={curated_carousel_listings}
              curated_stack_listings={curated_stack_listings}
              pollinator_listings={pollinator_listings}
              small_space_listings={small_space_listings}
              unique_listings={unique_listings}
              on_sale_listings={on_sale_listings}
            />
          );
          return (
            <>
              <Topbar as="header" className={css.topbar}>
                <TopbarContainer />
              </Topbar>
              <Main as="main" className={hasSideNav ? css.main_w_sidenav : css.main}>
                {sections.length === 0 && inProgress ? (
                  <LoadingSpinner />
                ) : pageContent
                }
              </Main>
              <Footer>
                <FooterContainer />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export { LayoutComposer, StaticPage, SectionBuilder };

export default PageBuilder;

import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Field, { hasDataInFields } from '../../Field';
import {
  NamedLink,
  ContactUsForm,
} from '../../../../components';
import BlockContainer from '../BlockContainer';

import css from './BlockFAQ.module.css';

const FieldMedia = props => {
  const { className, media, sizes, options, href } = props;
  const hasMediaField = hasDataInFields([media], options);
  if (hasMediaField && href) {
    return (
      <div className={classNames(className, css.media)}>
        <a href={href}>
          <Field data={media} sizes={sizes} options={options} />
        </a>
      </div>
    );
  }
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockFAQ = props => {
  const {
    blockId,
    sectionId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;
  const classes = classNames(rootClassName || css.root, className, css[`${sectionId}-root`]);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
  
  const href = callToAction?.content?.indexOf('hide-go-to') >= 0 && callToAction?.href ? callToAction?.href : null;

  const replaceStringTokens = (str, type) => {
    const output_parts = [];
    if (type === 'account-link' && `${str}`.indexOf('[account-link]') >= 0){
      const parts = `${str}`.split('[account-link]'); 
      for (var i = 0; i < parts.length; i++) {
        output_parts.push(<span key={i}>{parts[i]}</span>);
        if (i !== parts.length - 1) {
          output_parts.push(<a href="/account" key={i}>Account</a>);
        }
      }
    } else if (type === 'terms-links' && `${str}`.indexOf('[terms-links]') >= 0) {
      const parts = `${str}`.split('[terms-links]'); 
      for (var i = 0; i < parts.length; i++) {
        output_parts.push(<span key={i}>{parts[i]}</span>);
        if (i !== parts.length - 1) {
          output_parts.push((
            <ul>
              <li>
                View Costa Farms <a href="https://costafarms.com/policies/terms-of-service" target="_new">Terms of Service</a>
              </li>
              <li>
                View Crescent Gardens <a href="https://www.crescentgarden.com/wp-content/uploads/2020/05/USA-Terms-and-Conditions.pdf" target="_new">Terms of Service</a>
              </li>
              <li>
                View Green Promise Farms <a href="https://greenpromisefarms.com/policies/terms-of-service" target="_new">Terms of Service</a>
              </li>
              <li>  
                View Park Seed <a href="https://www.parkseed.com/terms-and-conditions" target="_new">Terms of Service</a>
              </li>
              <li>
                View Plant by Number <a href="https://pbndesign.com/terms-conditions/" target="_new">Terms of Service</a>
              </li>
              <li>
                View Vego Garden <a href="https://vegogarden.com/pages/terms-of-service" target="_new">Terms of Service</a>
              </li>
            </ul>
          ));
        }
      }
    } else if (type === 'gkh-terms-link' && `${str}`.indexOf('[gkh-terms]') >= 0){
      const parts = `${str}`.split('[gkh-terms]'); 
      for (var i = 0; i < parts.length; i++) {
        output_parts.push(<span key={i}>{parts[i]}</span>);
        if (i !== parts.length - 1) {
          output_parts.push(
          <>
            <span style={{ paddingRight: 8 }}>GKH: Shop Terms &amp; Conditions can be found</span>
            <NamedLink name="TermsOfServicePage">here</NamedLink>.
          </>);
        }
      }
    } else if (type === 'contact-us-form' && `${str}`.indexOf('[contact-us-form]') >= 0){
      const parts = `${str}`.split('[contact-us-form]'); 
      for (var i = 0; i < parts.length; i++) {
        output_parts.push(<span key={i}>{parts[i]}</span>);
        if (i !== parts.length - 1) {
          output_parts.push(
          <div style={{ marginTop: 20 }}>
            <ContactUsForm />
          </div>);
        }
      }
    }
    
    return <div>{output_parts.map(pi => pi)}</div>;
  }

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
        href={href}
      />
      {hasTextComponentFields && !href ? (
        <div className={classNames(textClassName, css.text)}>
          <Accordion
            elevation={0}
            className={css.accordion}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={css.accordionSummary}
              classes={{
                content: css.accordionSummaryContent,
              }}
            >
              <Field data={title} options={options} />
            </AccordionSummary>
            <AccordionDetails
              className={css.accordionDetails}
            >
              {
                `${text.content}`.indexOf('[account-link]') >= 0
                && replaceStringTokens(text.content, 'account-link')
              }
              {
                `${text.content}`.indexOf('[terms-links]') >= 0
                && replaceStringTokens(text.content, 'terms-links')
              }
              {
                `${text.content}`.indexOf('[gkh-terms]') >= 0
                && replaceStringTokens(text.content, 'gkh-terms-link')
              }
              {
                `${text.content}`.indexOf('[contact-us-form]') >= 0
                && replaceStringTokens(text.content, 'contact-us-form')
              }
              {
                `${text.content}`.indexOf('[account-link]') < 0
                && `${text.content}`.indexOf('[terms-links]') < 0
                && `${text.content}`.indexOf('[gkh-terms]') < 0
                && `${text.content}`.indexOf('[contact-us-form]') < 0
                && text.content
              }
            </AccordionDetails>
          </Accordion>
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockFAQ.defaultProps = {
  stringId: null,
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockFAQ.propTypes = {
  blockId: string.isRequired,
  sectionId: string,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockFAQ;
